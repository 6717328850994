<template>
  <div class="terms-and-conditions-page">
    <div class="readable_content">
      <a @click="goBack" v-if="isPrevRouteRegister">&larr; Back to Sign Up</a>
      <h1>Terms and Conditions</h1>

      <h4>Introduction</h4>
      <div class="heading-6">Acknowledgement</div>
      <p>
        Welcome to My China Roots! These Terms and Conditions ("Terms") govern Your access to and use of our genealogy
        services, including any content, functionality, and subscriptions offered on or through
        <a href="https://www.mychinaroots.com/">https://www.mychinaroots.com/</a>. By accessing or using our services,
        You agree to be bound by these Terms. If You do not agree, please refrain from using our services.
      </p>
      <p>These Terms were last updated on November 28, 2024.</p>

      <div class="heading-6">Definitions</div>
      <p>
        <span class="bold">"Services"</span>: The genealogy research and translation tools, databases, custom research
        services and other offerings available on My China Roots.
      </p>
      <p>
        <span class="bold">"Subscription Plan"</span>: A paid tier of service granting access to premium content or
        tools.
      </p>
      <p>
        <span class="bold">"Content"</span>: Includes all website materials, including text, images, reports, and
        user-generated content.
      </p>
      <p>
        <span class="bold">“Account”</span>: means a unique account created for You to access our Service or parts of
        our Service.
      </p>
      <p>
        <span class="bold">"Company”</span>: (referred to as either “My China Roots”, "the Company", "We", "Us" or "Our"
        in this Agreement) refers to My Chinese Roots PTE. Ltd, #09-01, 20 Collyer Quay, Singapore, 049319.
      </p>
      <p><span class="bold">“Country”</span>: refers to Singapore.</p>
      <p>
        <span class="bold">“Device”</span>: means any device that can access the Service such as a computer, a cellphone
        or a digital tablet.
      </p>
      <p>
        <span class="bold">“Terms and Conditions”</span>: (also referred as "Terms") mean these Terms and Conditions
        that form the entire agreement between You and the Company regarding the use of the Service.
      </p>
      <p>
        <span class="bold">“Website”</span> refers to My China Roots, accessible from
        <a href="https://www.mychinaroots.com">https://www.mychinaroots.com</a>
      </p>
      <p>
        <span class="bold">“You”</span> means the individual accessing or using the Service, or the company, or other
        legal entity on behalf of which such individual is accessing or using the Service, as applicable.
      </p>
      <h4>Subscription and Account Terms</h4>
      <div class="heading-6">Your Account</div>
      <p>
        To use our Services, You must create an account. By creating an account, You affirm that You are at least 13
        years old. Children under the age of 13 are strictly prohibited from creating accounts or using the Services.
        Additionally, users must be individuals who have reached the age of consent in their jurisdiction for entering
        into legally binding agreements.
      </p>
      <div class="heading-6">User Responsibilities</div>
      <p>
        Account holders are responsible for ensuring that the information provided during registration is accurate and
        up-to-date. You are also responsible for maintaining the confidentiality of Your account credentials and for all
        activities conducted under Your account. Sharing Your account with others is prohibited.
      </p>
      <div class="heading-6">Subscriptions</div>
      <p>
        Subscription plans are personal and non-transferable. Details of our available subscription tiers, including
        features and pricing, are provided on our Subscription Plans Page. If a free trial is offered, it is limited to
        one trial per person and subject to the terms communicated during the trial offer.
      </p>

      <h4>Payment Terms & Refunds</h4>
      <p>
        Subscriptions are billed in advance on a monthly or annual basis, depending on the plan selected. Accepted
        payment methods include Visa and MasterCard. Subscriptions renew automatically unless canceled before the next
        billing cycle.
      </p>
      <p>We offer refunds under the following conditions:</p>
      <ul>
        <li>A subscription was charged in error.</li>
        <li>A technical issue prevented You from using our service, verified by our support team.</li>
      </ul>
      <p>Refunds for partial months are not available.</p>

      <h4>My China Roots Content</h4>
      <p>
        The Services include photographs, videos, documents, records, content indexes, and other materials made
        available to You by My China Roots ("My China Roots Content"). The availability of this content may vary based
        on Your subscription plan and geographic location. All such content is provided for Your use exclusively under
        the terms outlined in this agreement.
      </p>
      <div class="heading-6">Intellectual Property</div>
      <p>
        All content and materials on My China Roots, including but not limited to software, designs, and text, are owned
        by My China Roots or licensed to us. You may not copy, reproduce, distribute, or create derivative works without
        our express permission.
      </p>
      <p>As a user, You are granted a limited license to access our data for personal, non-commercial use only.</p>
      <div class="heading-6">Intellectual Property Infringement</div>
      <p>
        We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content
        posted on the Service infringes a copyright or other intellectual property infringement of any person.
      </p>
      <p>
        If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been
        copied in a way that constitutes copyright infringement that is taking place through the Service, You must
        submit Your notice in writing to the attention of our copyright agent via email at
        <a href="mailto:huihanlie@mychinaroots.com">huihanlie@mychinaroots.com</a> and include in Your notice a detailed
        description of the alleged infringement.
      </p>
      <p>
        You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any
        Content is infringing Your copyright.
      </p>
      <h4>DMCA Notice and DMCA Procedure for Copyright Infringement Claims</h4>
      <p>
        You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright
        Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
      </p>
      <ul>
        <li>
          An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's
          interest.
        </li>
        <li>
          A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page
          address) of the location where the copyrighted work exists or a copy of the copyrighted work.
        </li>
        <li>
          Identification of the URL or other specific location on the Service where the material that You claim is
          infringing is located.
        </li>
        <li>Your address, telephone number, and email address.</li>
        <li>
          A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright
          owner, its agent, or the law.
        </li>
        <li>
          A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and
          that You are the copyright owner or authorized to act on the copyright owner's behalf.
        </li>
      </ul>
      <p>
        You can contact our copyright agent via email at
        <a href="mailto:huihanlie@mychinaroots.com">huihanlie@mychinaroots.com</a>. Upon receipt of a notification, the
        Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the
        challenged content from the Service.
      </p>
      <div class="heading-6">Links to Other Websites</div>
      <p>
        Our Service may contain links to third-party web sites or services that are not owned or controlled by the
        Company.
      </p>
      <p>
        The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices
        of any third party web sites or services. You further acknowledge and agree that the Company shall not be
        responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
        connection with the use of or reliance on any such content, goods or services available on or through any such
        web sites or services.
      </p>
      <p>
        We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or
        services that You visit.
      </p>
      <h4>Your Content</h4>
      <p>
        Our Service allows You to post Content, such as family trees and photos. You are responsible for the Content
        that You post to the Service, including its legality, reliability, and appropriateness. Any Content You
        contribute and that contains Personal Information will be treated in accordance with our
        <router-link :to="{name: 'privacy-policy'}">Privacy Policy</router-link>.
      </p>
      <div class="heading-6">Ownership & User Responsibilities</div>
      <p>
        By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly
        display, reproduce, and distribute such Content on and through the Service. You retain any and all of Your
        rights to any Content You submit, post or display on or through the Service and You are responsible for
        protecting those rights. You agree that this license includes the right for Us to make Your Content available to
        other users of the Service, who may also use Your Content subject to these Terms.
      </p>
      <p>
        You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant
        Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the
        Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights
        of any person.
      </p>
      <div class="heading-6">Content Restrictions</div>
      <p>
        You are solely responsible for the content You upload, and for all activity that occurs under Your account. You
        may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous,
        defamatory, obscene or otherwise objectionable.
      </p>
      <div class="heading-6">Content Backups</div>
      <p>
        Although regular backups of Content are performed, the Company does not guarantee there will be no loss or
        corruption of data. Corrupt or invalid backup points may be caused by, without limitation, Content that is
        corrupted prior to being backed up or that changes during the time a backup is performed. The Company will
        provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of
        Content. But You acknowledge that the Company has no liability related to the integrity of Content or the
        failure to successfully restore Content to a usable state. You agree to maintain a complete and accurate copy of
        any Content in a location independent of the Service.
      </p>
      <h4>Data Privacy</h4>
      <p>
        We prioritize Your privacy. Please refer to our
        <router-link :to="{name: 'privacy-policy'}">Privacy Policy</router-link> for information about how we collect,
        use, and protect Your personal data. By using our services, You consent to the practices described in the
        Privacy Policy.
      </p>
      <h4>Limitations of Liability</h4>
      <p>
        My China Roots makes no guarantees about the accuracy or completeness of the genealogical data provided. We are
        not responsible for:
      </p>
      <ul>
        <li>Errors in historical records.</li>
        <li>Loss or damage resulting from service interruptions.</li>
        <li>Acts of third-party providers.</li>
      </ul>
      <p>Our liability is limited to the amount paid for Your subscription.</p>
      <h4>Termination</h4>
      <p>
        You may cancel your subscription at any time through your account settings. Upon termination, your access to
        subscription features will continue until the end of the current subscription period. My China Roots reserves
        the right to terminate accounts for breach of these Terms or misuse of the Services.
      </p>
      <h4>"AS IS" and "AS AVAILABLE" Disclaimer</h4>
      <p>
        The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of
        any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of
        its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties,
        whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties
        of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise
        out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing,
        the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will
        meet Your requirements, achieve any intended results, be compatible or work with any other software,
        applications, systems or services, operate without interruption, meet any performance or reliability standards
        or be error free or that any errors or defects can or will be corrected.
      </p>
      <p>
        Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation
        or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the
        information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or
        error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through
        the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the
        Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable
        statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But
        in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent
        enforceable under applicable law.
      </p>
      <h4>Governing Law</h4>
      <p>
        The laws of Singapore, excluding its conflicts of law rules, shall govern this Terms and Your use of the
        Service. Your use of the Application may also be subject to other local, state, national, or international laws.
      </p>
      <h4>Disputes Resolution</h4>
      <p>
        If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally
        by contacting the Company.
      </p>
      <h4>For European Union (EU) Users</h4>
      <p>
        If You are a European Union consumer, You will benefit from any mandatory provisions of the law of the country
        in which You are resident in.
      </p>
      <h4>United States Legal Compliance</h4>
      <p>
        You represent and warrant that (i) You are not located in a country that is subject to the United States
        government embargo, or that has been designated by the United States government as a "terrorist supporting"
        country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.
      </p>
      <h4>Severability and Waiver</h4>
      <div class="heading-6">Severability</div>
      <p>
        If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
        interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law
        and the remaining provisions will continue in full force and effect.
      </p>
      <div class="heading-6">Waiver</div>
      <p>
        Except as provided herein, the failure to exercise a right or to require performance of an obligation under this
        Terms shall not effect a party's ability to exercise such right or require such performance at any time
        thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.
      </p>
      <h4>Modifications to the Service or Terms</h4>
      <p>
        We may update our Services or these Terms at any time. Significant changes will be communicated via email or
        through a notice on our Website. Continued use of our Services after changes take effect constitutes Your
        acceptance of the updated Terms.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isPrevRouteRegister() {
      return this.$store.getters.previousRouteState && this.$store.getters.previousRouteState.name === 'register';
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.readable_content {
  ul {
    margin-block-start: 12px;
    margin-block-end: 12px;
  }
}
</style>
