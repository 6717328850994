<template>
  <div class="privacy-policy-page">
    <div class="readable_content">
      <a @click="goBack" v-if="isPrevRouteRegister">&larr; Back to Sign Up</a>
      <h1>Privacy Policy</h1>
      <h4>Introduction</h4>
      <div class="heading-6">Acknowledgement</div>
      <p>
        Welcome to My China Roots! We value Your trust and are committed to protecting Your privacy. This Privacy Policy
        explains how we collect, use, share, and protect Your personal information when You use our genealogy services,
        including our website, custom genealogy research services, family tree, and other offerings (collectively, the
        "Services").
      </p>
      <p>
        Our Privacy Policy applies to all users worldwide, including those in the European Union (EU) and California,
        and is designed to comply with the General Data Protection Regulation (GDPR) and the California Consumer Privacy
        Act (CCPA). By using our Services, You agree to the practices described in this policy. If You do not agree,
        please refrain from using our Services.
      </p>
      <p>
        If You have any questions or concerns about this Privacy Policy or how we handle Your personal information,
        please contact us at:
        <br />
        Email: <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a>
      </p>
      <p>This policy was last updated on December 9, 2024.</p>
      <div class="heading-6">Definitions</div>
      <p>
        <span class="bold">"Services"</span>: The genealogy research and translation tools, databases, custom research
        services and other offerings available on My China Roots.
      </p>
      <p>
        <span class="bold">"Subscription Plan"</span>: A paid tier of service granting access to premium content or
        tools.
      </p>
      <p>
        <span class="bold">"Content"</span>: Includes all website materials, including text, images, reports, and
        user-generated content.
      </p>
      <p>
        <span class="bold">“Account”</span>: means a unique account created for You to access our Service or parts of
        our Service.
      </p>
      <p>
        <span class="bold">"Company”</span>: (referred to as either “My China Roots”, "the Company", "We", "Us" or "Our"
        in this Agreement) refers to My Chinese Roots PTE. Ltd, #09-01, 20 Collyer Quay, Singapore, 049319.
      </p>
      <p><span class="bold">“Country”</span>: refers to Singapore.</p>
      <p>
        <span class="bold">“Device”</span>: means any device that can access the Service such as a computer, a cellphone
        or a digital tablet.
      </p>
      <p>
        <span class="bold">“Terms and Conditions”</span>: (also referred as "Terms") mean these Terms and Conditions
        that form the entire agreement between You and the Company regarding the use of the Service.
      </p>
      <p>
        <span class="bold">“Website”</span> refers to My China Roots, accessible from
        <a href="https://www.mychinaroots.com">https://www.mychinaroots.com</a>
      </p>
      <p>
        <span class="bold">“You”</span> means the individual accessing or using the Service, or the company, or other
        legal entity on behalf of which such individual is accessing or using the Service, as applicable.
      </p>
      <h4>Data We Collect</h4>
      <p>
        We collect various types of information to provide and improve our genealogy services. The data we collect
        includes:
      </p>
      <div class="heading-6">Personal Information You Provide</div>
      <p>When You interact with our Services, You may provide the following information:</p>
      <ul>
        <li>
          <span class="bold">Account Information</span>: Your name, email address, password, billing address, and
          payment details (e.g., credit card information).
        </li>
        <li>
          <span class="bold">Genealogical Data</span>: Family trees, names, photos, records, and other content You
          upload.
        </li>
        <li>
          <span class="bold">Contact Information</span>: Details provided when contacting customer support or
          subscribing to newsletters.
        </li>
      </ul>
      <div class="heading-6">Data Collected Automatically</div>
      <p>When You use our Services, we may collect certain information automatically, including:</p>
      <ul>
        <li>
          <span class="bold">Device Information</span>: Your IP address, browser type, operating system, and device
          identifiers.
        </li>
        <li>
          <span class="bold">Usage Data</span>: Information about how You interact with our Services, such as pages
          viewed, search queries, and subscription usage.
        </li>
        <li>
          <span class="bold">Cookies and Tracking Technologies</span>: See section entitled “Tracking Technologies and
          Cookies” for more details.
        </li>
      </ul>
      <div class="heading-6">Data from Third Party Social Media Services</div>
      <p>
        The Company allows You to create an account and log in to use the Service through the following Third-party
        Social Media Services:
      </p>
      <ul>
        <li>Google</li>
        <li>Facebook</li>
      </ul>
      <p>
        If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may
        collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as
        Your name, Your email address, Your activities or Your contact list associated with that account.
      </p>
      <p>
        You may also have the option of sharing additional information with the Company through Your Third-Party Social
        Media Service's account. If You choose to provide such information and Personal Data, during registration or
        otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this
        Privacy Policy.
      </p>
      <div class="heading-6">Sensitive Data</div>
      <p>Some data You provide may be considered sensitive under certain privacy laws, such as:</p>
      <ul>
        <li>Ethnic or racial origin inferred from genealogical data.</li>
        <li>Historical information relating to family members.</li>
      </ul>
      <p>We collect and process sensitive data only with Your explicit consent, as required by applicable laws.</p>
      <h4>Tracking Technologies and Cookies</h4>
      <p>
        We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
        information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:
      </p>
      <p>
        Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to
        refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may
        not be able to use some parts of our Service. Unless You have adjusted Your browser setting so that it will
        refuse Cookies, our Service may use Cookies.
      </p>
      <p>
        Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web
        beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for
        example, to count users who have visited those pages or opened an email and for other related website statistics
        (for example, recording the popularity of a certain section and verifying system and server integrity).
      </p>
      <p>
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile
        device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can
        learn more about cookies on TermsFeed website article.
      </p>
      <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
      <span class="bold">1. Necessary / Essential Cookies</span>
      <p>Type: Session Cookies</p>
      <p>Administered by: Us</p>
      <p>
        Purpose: These Cookies are essential to provide You with services available through the Website and to enable
        You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts.
        Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to
        provide You with those services.
      </p>
      <span class="bold">2. Cookies Policy / Notice Acceptance Cookies</span>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Us</p>
      <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
      <span class="bold">3. Functionality Cookies</span>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Us</p>
      <p>
        Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering Your
        login details or language preference. The purpose of these Cookies is to provide You with a more personal
        experience and to avoid You having to re-enter Your preferences every time You use the Website.
      </p>
      <span class="bold">4. Tracking and Performance Cookies </span>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Third-Parties</p>
      <p>
        Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website.
        The information gathered via these Cookies may directly or indirectly identify You as an individual visitor.
        This is because the information collected is typically linked to a pseudonymous identifier associated with the
        device You use to access the Website. We may also use these Cookies to test new pages, features or new
        functionality of the Website to see how our users react to them.
      </p>
      <p>
        For more information about the cookies we use and Your choices regarding cookies, please visit our Cookies
        Policy or the Cookies section of our Privacy Policy.
      </p>
      <h4>How We Use Your Data</h4>
      <p>
        We use the personal information we collect to deliver, improve, and personalize our genealogy services. Below
        are the specific purposes for which we use Your data:
      </p>
      <p>
        <span class="bold">To provide and maintain our Services</span>, including creating and managing Your account.;
        processing payments and subscription plans; delivering access to genealogical databases, tools, and resources;
        generating and sharing genealogical insights based on the information You provide.
      </p>
      <p>
        <span class="bold">To improve our Services</span>, including analyzing user behavior to enhance features,
        optimizing our website, and ensuring a seamless user experience; conducting research and developing new tools or
        features for genealogical discovery.
      </p>
      <p>
        <span class="bold">To communicate with You</span>, including sending important account notifications, such as
        subscription renewals or updates to our terms and policies; providing customer support, responding to Your
        inquiries; delivering marketing communications and special offers if You have opted in to receive them.
      </p>
      <p>
        <span class="bold">To ensure security and prevent misuse</span>, such as detecting and preventing fraudulent
        activity, unauthorized access, or other misuse of our Services; enforcing our Terms and Conditions and complying
        with legal requirements; meeting regulatory, tax, and accounting obligations.
      </p>
      <p>
        <span class="bold">For business transfers</span>: We may use Your information to evaluate or conduct a merger,
        divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets,
        whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data
        held by Us about our Service users is among the assets transferred.
      </p>
      <div class="heading-6">Retention of Your Personal Data</div>
      <p>
        The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
        Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain Your data to comply with applicable laws), resolve
        disputes, and enforce our legal agreements and policies.
      </p>
      <p>
        The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
        shorter period of time, except when this data is used to strengthen the security or to improve the functionality
        of Our Service, or We are legally obligated to retain this data for longer time periods.
      </p>
      <h4>Transfer of Your Personal Data</h4>
      <p>
        Your information, including Personal Data, is processed at the Company's operating offices and in any other
        places where the parties involved in the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your state, province, country or other
        governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
      </p>
      <p>
        Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to
        that transfer.
      </p>
      <p>
        The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
        accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the security of Your data and other personal
        information.
      </p>
      <div class="heading-6">Delete Your Personal Data</div>
      <p>
        You have the right to delete or request that We assist in deleting the Personal Data that We have collected
        about You.
      </p>
      <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
      <p>
        You may update, amend, or delete Your information at any time by signing in to Your Account at
        <router-link :to="{name: 'user-profile'}">https://www.mychinaroots.com/profile</router-link>, if You have one,
        and visiting the account settings section that allows You to manage Your personal information. You may also
        contact Us to request access to, correct, or delete any personal information that You have provided to Us at
        <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a>.
      </p>
      <p>
        Please note, however, that We may need to retain certain information when we have a legal obligation or lawful
        basis to do so.
      </p>
      <div class="heading-6">Disclosure of Your Personal Data</div>
      <p>We may share Your personal information in the following situations:</p>
      <p>
        <span class="bold">With Service Providers</span>: We may share Your personal information with Service Providers
        to monitor and analyze the use of our Service, for payment processing, to contact You.
      </p>
      <p>
        <span class="bold">For business transfers</span>: We may share or transfer Your personal information in
        connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all
        or a portion of Our business to another company.
      </p>
      <p>
        <span class="bold">With Affiliates</span>: We may share Your information with Our affiliates, in which case we
        will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other
        subsidiaries, joint venture partners or other companies that We control or that are under common control with
        Us.
      </p>
      <p>
        <span class="bold">With business partners</span>: We may share Your information with Our business partners to
        offer You certain products, services or promotions.
      </p>
      <p>
        <span class="bold">With other users</span>: when You share personal information or otherwise interact in the
        public areas with other users, such information may be viewed by all users and may be publicly distributed
        outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts
        on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity.
        Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your
        profile.
      </p>
      <p>
        <span class="bold">With Your consent</span>: We may disclose Your personal information for any other purpose
        with Your consent.
      </p>
      <p>
        You have the right to withdraw Your consent at any time, which will not affect the lawfulness of processing
        before withdrawal. For more information, contact
        <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a>.
      </p>
      <div class="heading-6">Security of Your Personal Data</div>
      <p>
        The security of Your Personal Data is important to Us, but remember that no method of transmission over the
        Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means
        to protect Your Personal Data, We cannot guarantee its absolute security.
      </p>
      <div class="heading-6">Detailed Information on the Processing of Your Personal Data</div>
      <p>
        The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store,
        use, process and transfer information about Your activity on Our Service in accordance with their Privacy
        Policies.
      </p>
      <div class="heading-6">Analytics</div>
      <p>We may use third-party Service providers to monitor and analyze the use of our Service.</p>
      <p>
        <span class="bold">Google Analytics</span> is a web analytics service offered by Google that tracks and reports
        website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared
        with other Google services. Google may use the collected data to contextualize and personalize the ads of its
        own advertising network.
      </p>
      <p>
        You can opt-out of having made Your activity on the Service available to Google Analytics by installing the
        Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
        analytics.js and dc.js) from sharing information with Google Analytics about visits activity.
      </p>
      <p>
        For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page:
        <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>
      </p>
      <p>
        <span class="bold">PostHog</span> is a a digital analytics platform that helps businesses understand customer
        behavior and improve their products. Their Privacy Policy can be viewed at
        <a href="https://posthog.com/privacy" target="_blank">https://posthog.com/privacy</a>
      </p>
      <div class="heading-6">Email Marketing</div>
      <p>
        We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other
        information that may be of interest to You. You may opt-out of receiving any, or all, of these communications
        from Us by following the unsubscribe link or instructions provided in any email We send or by editing Your email
        communication preferences on Your profile:
        <router-link :to="{name: 'user-profile'}">https://www.mychinaroots.com/profile</router-link>.
      </p>
      <p>
        We use Elastic Email, an email marketing service provider to manage and send emails to You. Their Privacy Policy
        can be viewed at
        <a href="https://elasticemail.com/resources/usage-policies/privacy-policy"
          >https://elasticemail.com/resources/usage-policies/privacy-policy</a
        >
      </p>
      <div class="heading-6">Payments</div>
      <p>
        We may provide paid products and/or services within the Service. In that case, we may use third-party services
        for payment processing (e.g. payment processors).
      </p>
      <p>
        We will not store or collect Your payment card details. That information is provided directly to Our third-party
        payment processors whose use of Your personal information is governed by their Privacy Policy. These payment
        processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a
        joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure
        the secure handling of payment information.
      </p>
      <p>
        <span class="bold">Stripe</span>: Their Privacy Policy can be viewed at
        <a href="https://stripe.com/us/privacy" target="_blank">https://stripe.com/us/privacy</a>
      </p>
      <p>
        <span class="bold">PayPal</span>: Their Privacy Policy can be viewed at
        <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" target="_blank"
          >https://www.paypal.com/webapps/mpp/ua/privacy-full</a
        >
      </p>
      <p>
        When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide
        information to facilitate this transaction and to verify Your identity.
      </p>
      <h4>GDPR Privacy</h4>
      <div class="heading-6">Legal Basis for Processing Personal Data under GDPR</div>
      <p>We may process Personal Data under the following conditions:</p>
      <ul>
        <li>
          <span class="bold">Consent</span>: You have given Your consent for processing Personal Data for one or more
          specific purposes.
        </li>
        <li>
          <span class="bold">Performance of a contract</span>: Provision of Personal Data is necessary for the
          performance of an agreement with You and/or for any pre-contractual obligations thereof.
        </li>
        <li>
          <span class="bold">Legal obligations</span>: Processing Personal Data is necessary for compliance with a legal
          obligation to which the Company is subject.
        </li>
        <li>
          <span class="bold">Vital interests</span>: Processing Personal Data is necessary in order to protect Your
          vital interests or of another natural person.
        </li>
        <li>
          <span class="bold">Public interests</span>: Processing Personal Data is related to a task that is carried out
          in the public interest or in the exercise of official authority vested in the Company.
        </li>
        <li>
          <span class="bold">Legitimate interests</span>: Processing Personal Data is necessary for the purposes of the
          legitimate interests pursued by the Company.
        </li>
      </ul>
      <p>
        In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing,
        and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a
        requirement necessary to enter into a contract.
      </p>
      <div class="heading-6">Your Rights under the GDPR</div>
      <p>
        The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise
        Your rights.
      </p>
      <p>You have the right under this Privacy Policy, and by law if You are within the EU, to:</p>
      <ul>
        <li>
          <span class="bold">Request access to Your Personal Data</span>. The right to access, update or delete the
          information We have on You. Contact <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a> to
          receive a copy of the Personal Data We hold about You.
        </li>
        <li>
          <span class="bold">Request correction of the Personal Data that We hold about You</span>. You have the right
          to have any incomplete or inaccurate information We hold about You corrected.
        </li>
        <li>
          <span class="bold">Object to processing of Your Personal Data</span>. This right exists where We are relying
          on a legitimate interest as the legal basis for Our processing and there is something about Your particular
          situation, which makes You want to object to our processing of Your Personal Data on this ground. You also
          have the right to object where We are processing Your Personal Data for direct marketing purposes.
        </li>
        <li>
          <span class="bold">Request erasure of Your Personal Data</span>. You have the right to ask Us to delete or
          remove Personal Data when there is no good reason for Us to continue processing it by contacting
          <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a>.
        </li>
        <li>
          <span class="bold">Request the transfer of Your Personal Data</span>. We will provide to You, or to a
          third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format.
          Please note that this right only applies to automated information which You initially provided consent for Us
          to use or where We used the information to perform a contract with You.
        </li>
        <li>
          <span class="bold">Withdraw Your consent</span>. You have the right to withdraw Your consent on using Your
          Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific
          functionalities of the Service.
        </li>
      </ul>
      <div class="heading-6">Exercising of Your GDPR Data Protection Rights</div>
      <p>
        You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us at
        <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a>. Please note that we may ask You to
        verify Your identity before responding to such requests. If You make a request, We will try our best to respond
        to You as soon as possible.
      </p>
      <p>
        You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal
        Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data
        protection authority in the EEA.
      </p>
      <h4>CCPA/CPRA Privacy Notice (California Privacy Rights)</h4>
      <p>
        This privacy notice section for California residents supplements the information contained in Our Privacy Policy
        and it applies solely to all visitors, users, and others who reside in the State of California.
      </p>
      <div class="heading-6">Categories of Personal Information Collected</div>
      <p>
        We collect information that identifies, relates to, describes, references, is capable of being associated with,
        or could reasonably be linked, directly or indirectly, with a particular Consumer or Device. The following is a
        list of categories of personal information which we may collect or may have been collected from California
        residents within the last twelve (12) months.
      </p>
      <p>
        Please note that the categories and examples provided in the list below are those defined in the CCPA/CPRA. This
        does not mean that all examples of that category of personal information were in fact collected by Us, but
        reflects our good faith belief to the best of Our knowledge that some of that information from the applicable
        category may be and may have been collected. For example, certain categories of personal information would only
        be collected if You provided such personal information directly to Us.
      </p>
      <div class="offset">
        <p class="bold">Category A: Identifiers.</p>
        <p>Examples: name, alias, Internet Protocol address, email address.</p>
        <p>Collected: Yes.</p>
      </div>
      <div class="offset">
        <p class="bold">
          Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code
          § 1798.80(e)).
        </p>
        <p>Examples: physical characteristics or description.</p>
        <p>Collected: Yes.</p>
      </div>
      <div class="offset">
        <p class="bold">Category C: Protected classification characteristics under California or federal law.</p>
        <p>Examples: age, sex, citizenship.</p>
        <p>Collected: Yes.</p>
      </div>
      <div class="offset">
        <p class="bold">Category D: Commercial information.</p>
        <p>Examples: Records and history of products or services purchased or considered.</p>
        <p>Collected: Yes.</p>
      </div>
      <div class="offset">
        <p class="bold">Category E: Biometric information.</p>
        <p>Examples: Genetic or health data.</p>
        <p>Collected: No.</p>
      </div>
      <div class="offset">
        <p class="bold">Category F: Internet or other similar network activity.</p>
        <p>Examples: browsing history, search history on the Service.</p>
        <p>Collected: Yes.</p>
      </div>
      <div class="offset">
        <p class="bold">Category G: Geolocation data.</p>
        <p>Examples: Approximate physical location.</p>
        <p>Collected: Yes.</p>
      </div>
      <div class="offset">
        <p class="bold">Category H: Sensory data.</p>
        <p>Examples: Audio, electronic, visual, thermal, olfactory, or similar information.</p>
        <p>Collected: No.</p>
      </div>
      <div class="offset">
        <p class="bold">Category I: Professional or employment-related information.</p>
        <p>Examples: Current or past job history or performance evaluations.</p>
        <p>Collected: No.</p>
      </div>
      <div class="offset">
        <p class="bold">
          Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C.
          Section 1232g, 34 C.F.R. Part 99)).
        </p>
        <p>Examples: Education records.</p>
        <p>Collected: No.</p>
      </div>
      <div class="offset">
        <p class="bold">Category K: Inferences drawn from other personal information.</p>
        <p>
          Examples: Profile reflecting a person's preferences, characteristics, psychological trends, predispositions,
          behavior, attitudes, intelligence, abilities, and aptitudes.
        </p>
        <p>Collected: No.</p>
      </div>
      <div class="offset">
        <p class="bold">Category L: Sensitive personal information.</p>
        <p>Examples: Account login and password information, geolocation data.</p>
        <p>Collected: yes.</p>
      </div>
      <p>Under CCPA/CPRA, personal information does not include:</p>
      <ul>
        <li>Publicly available information from government records</li>
        <li>Deidentified or aggregated consumer information</li>
        <li>Information excluded from the CCPA/CPRA's scope, such as:</li>
        <li class="offset">
          Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996
          (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data
        </li>
        <li class="offset">
          Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act
          (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the
          Driver's Privacy Protection Act of 1994
        </li>
      </ul>
      <div class="heading-6">Sources of Personal Information</div>
      <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
      <ul>
        <li>
          <span class="bold">Directly from You</span>. For example, from the forms You complete on our Service,
          preferences You express or provide through our Service, or from Your purchases on our Service.
        </li>
        <li>
          <span class="bold">Indirectly from You</span>. For example, from observing Your activity on our Service.
        </li>
        <li>
          <span class="bold">Automatically from You</span>. For example, through cookies We or our Service Providers set
          on Your Device as You navigate through our Service.
        </li>
        <li>
          <span class="bold">From Service Providers</span>. For example, third-party vendors to monitor and analyze the
          use of our Service, third-party vendors for payment processing, or other third-party vendors that We use to
          provide the Service to You.
        </li>
      </ul>
      <div class="heading-6">Use of Personal Information</div>
      <p>
        We may use or disclose personal information We collect for "business purposes" or "commercial purposes" (as
        defined under the CCPA/CPRA), which may include the following examples:
      </p>
      <ul>
        <li>To operate our Service and provide You with Our Service.</li>
        <li>
          To provide You with support and to respond to Your inquiries, including to investigate and address Your
          concerns and monitor and improve our Service.
        </li>
        <li>
          To fulfill or meet the reason You provided the information. For example, if You share Your contact information
          to ask a question about our Service, We will use that personal information to respond to Your inquiry. If You
          provide Your personal information to purchase a product or service, We will use that information to process
          Your payment and facilitate delivery.
        </li>
        <li>
          To respond to law enforcement requests and as required by applicable law, court order, or governmental
          regulations.
        </li>
        <li>
          As described to You when collecting Your personal information or as otherwise set forth in the CCPA/CPRA.
        </li>
        <li>For internal administrative and auditing purposes.</li>
        <li>
          To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity,
          including, when necessary, to prosecute those responsible for such activities.
        </li>
        <li>Other one-time uses.</li>
      </ul>
      <p>
        Please note that the examples provided above are illustrative and not intended to be exhaustive. For more
        details on how we use this information, please refer to the "Use of Your Personal Data" section.
      </p>
      <p>
        If We decide to collect additional categories of personal information or use the personal information We
        collected for materially different, unrelated, or incompatible purposes, We will update this Privacy Policy.
      </p>
      <div class="heading-6">Sale of Personal Information</div>
      <p>
        As defined in the CCPA/CPRA, "sell" and "sale" mean selling, renting, releasing, disclosing, disseminating,
        making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means,
        a Consumer's personal information by the Business to a third party for valuable consideration. This means that
        We may have received some kind of benefit in return for sharing personal information, but not necessarily a
        monetary benefit.
      </p>
      <p>
        We do not sell personal information as the term sell is commonly understood. We do allow Service Providers to
        use Your personal information for the business purposes described in Our Privacy Policy, for activities such as
        advertising, marketing, and analytics, and these may be deemed a sale under CCPA/CPRA.
      </p>
      <div class="heading-6">Sale of Personal Information of Minors Under 16 Years of Age</div>
      <p>
        We do not knowingly collect personal information from minors under the age of 16 through our Service, although
        certain third party websites that we link to may do so. These third-party websites have their own terms of use
        and privacy policies and We encourage parents and legal guardians to monitor their children's Internet usage and
        instruct their children to never provide information on other websites without their permission.
      </p>
      <p>
        We do not sell the personal information of Consumers We actually know are less than 16 years of age, unless We
        receive affirmative authorization (the "right to opt-in") from either the Consumer who is between 13 and 16
        years of age, or the parent or guardian of a Consumer less than 13 years of age. Consumers who opt-in to the
        sale of personal information may opt-out of future sales at any time. To exercise the right to opt-out, You (or
        Your authorized representative) may submit a request to Us by contacting Us.
      </p>
      <p>
        If You have reason to believe that a child under the age of 13 (or 16) has provided Us with personal
        information, please contact Us with sufficient detail to enable Us to delete that information.
      </p>
      <div class="heading-6">Your Rights under the CCPA/CPRA</div>
      <p>
        The CCPA/CPRA provides California residents with specific rights regarding their personal information. If You
        are a resident of California, You have the following rights:
      </p>
      <p>
        <span class="bold">The right to notice</span>. You have the right to be notified which categories of Personal
        Data are being collected and the purposes for which the Personal Data is being used.
      </p>
      <p>
        <span class="bold">The right to know/access</span>. Under CCPA/CPRA, You have the right to request that We
        disclose information to You about Our collection, use, sale, disclosure for business purposes and share of
        personal information. Once We receive and confirm Your request, We will disclose to You:
      </p>
      <ul>
        <li>The categories of personal information We collected about You</li>
        <li>The categories of sources for the personal information We collected about You</li>
        <li>Our business or commercial purposes for collecting or selling that personal information</li>
        <li>The categories of third parties with whom We share that personal information</li>
        <li>The specific pieces of personal information We collected about You</li>
        <li>
          If we sold Your personal information or disclosed Your personal information for a business purpose, We will
          disclose to You:
        </li>
        <li class="offset">The categories of personal information categories sold</li>
        <li class="offset">The categories of personal information categories disclosed</li>
      </ul>
      <p>
        <span class="bold">The right to say no to the sale or sharing of Personal Data (opt-out)</span>. You have the
        right to direct Us to not sell Your personal information. To submit an opt-out request, please see the "Do Not
        Sell My Personal Information" section or contact Us.
      </p>
      <p>
        <span class="bold">The right to correct Personal Data</span>. You have the right to correct or rectify any
        inaccurate personal information about You that We collected. Once We receive and confirm Your request, We will
        use commercially reasonable efforts to correct (and direct our Service Providers to correct) Your personal
        information, unless an exception applies.
      </p>
      <p>
        <span class="bold">The right to limit use and disclosure of sensitive Personal Data</span>. You have the right
        to request to limit the use or disclosure of certain sensitive personal information We collected about You,
        unless an exception applies. To submit, please see the "Limit the Use or Disclosure of My Sensitive Personal
        Information" section or contact Us.
      </p>
      <p>
        <span class="bold">The right to delete Personal Data</span>. You have the right to request the deletion of Your
        Personal Data under certain circumstances, subject to certain exceptions. Once We receive and confirm Your
        request, We will delete (and direct Our Service Providers to delete) Your personal information from our records,
        unless an exception applies. We may deny Your deletion request if retaining the information is necessary for Us
        or Our Service Providers to:
      </p>
      <ul>
        <li>
          Complete the transaction for which We collected the personal information, provide a good or service that You
          requested, take actions reasonably anticipated within the context of our ongoing business relationship with
          You, or otherwise perform our contract with You.
        </li>
        <li>
          Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute
          those responsible for such activities.
        </li>
        <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
        <li>
          Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise
          another right provided for by law.
        </li>
        <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
        <li>
          Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that
          adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render
          impossible or seriously impair the research's achievement, if You previously provided informed consent.
        </li>
        <li>
          Enable solely internal uses that are reasonably aligned with consumer expectations based on Your relationship
          with Us.
        </li>
        <li>Comply with a legal obligation.</li>
        <li>
          Make other internal and lawful uses of that information that are compatible with the context in which You
          provided it.
        </li>
      </ul>
      <p>
        <span class="bold">The right not to be discriminated against</span>. You have the right not to be discriminated
        against for exercising any of Your consumer's rights, including by:
      </p>
      <ul>
        <li>Denying goods or services to You</li>
        <li>
          Charging different prices or rates for goods or services, including the use of discounts or other benefits or
          imposing penalties
        </li>
        <li>Providing a different level or quality of goods or services to You</li>
        <li>
          Suggesting that You will receive a different price or rate for goods or services or a different level or
          quality of goods or services
        </li>
      </ul>
      <div class="heading-6">Exercising Your CCPA/CPRA Data Protection Rights</div>
      <p>
        Please see the "Do Not Sell My Personal Information" section and "Limit the Use or Disclosure of My Sensitive
        Personal Information" section for more information on how to opt out and limit the use of sensitive information
        collected.
      </p>
      <p>
        Additionally, in order to exercise any of Your rights under the CCPA/CPRA, and if You are a California resident,
        You can contact Us:
      </p>
      <ul>
        <li>By email: <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a></li>
      </ul>
      <p>
        Only You, or a person registered with the California Secretary of State that You authorize to act on Your
        behalf, may make a verifiable request related to Your personal information.
      </p>
      <p>Your request to Us must:</p>
      <ul>
        <li>
          Provide sufficient information that allows Us to reasonably verify You are the person about whom We collected
          personal information or an authorized representative
        </li>
        <li>
          Describe Your request with sufficient detail that allows Us to properly understand, evaluate, and respond to
          it
        </li>
      </ul>
      <p>We cannot respond to Your request or provide You with the required information if We cannot:</p>
      <ul>
        <li>Verify Your identity or authority to make the request</li>
        <li>And confirm that the personal information relates to You</li>
      </ul>
      <p>
        We will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable
        request. The time period to provide the required information may be extended once by an additional 45 days when
        reasonably necessary and with prior notice.
      </p>
      <p>Any disclosures We provide will only cover the 12-month period preceding the verifiable request's receipt.</p>
      <p>
        For data portability requests, We will select a format to provide Your personal information that is readily
        usable and should allow You to transmit the information from one entity to another entity without hindrance.
      </p>
      <div class="heading-6">Do Not Sell My Personal Information</div>
      <p>
        As defined in the CCPA/CPRA, "sell" and "sale" mean selling, renting, releasing, disclosing, disseminating,
        making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means,
        a Consumer's personal information by the Business to a third party for valuable consideration. This means that
        We may have received some kind of benefit in return for sharing personal information, but not necessarily a
        monetary benefit.
      </p>
      <p>
        We do not sell personal information as the term sell is commonly understood. We do allow Service Providers to
        use Your personal information for the business purposes described in Our Privacy Policy, for activities such as
        advertising, marketing, and analytics, and these may be deemed a sale under CCPA/CPRA.
      </p>
      <p>
        You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a verifiable
        consumer request from You, we will stop selling Your personal information. To exercise Your right to opt-out,
        please contact Us at <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a>.
      </p>
      <p>
        The Service Providers we partner with (for example, our analytics or advertising partners) may use technology on
        the Service that sells personal information as defined by the CCPA/CPRA law. If You wish to opt out of the use
        of Your personal information for interest-based advertising purposes and these potential sales as defined under
        CCPA/CPRA law, You may do so by following the instructions below.
      </p>
      <p>
        Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that
        You use.
      </p>
      <p>
        <span class="bold">Mobile devices</span>: Your mobile device may give You the ability to opt out of the use of
        information about the apps You use in order to serve You ads that are targeted to Your interests:
      </p>
      <ul>
        <li>"Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices</li>
        <li>"Limit Ad Tracking" on iOS devices</li>
      </ul>
      <p>
        You can also stop the collection of location information from Your mobile device by changing the preferences on
        Your mobile device.
      </p>
      <div class="heading-6">Limit the Use or Disclosure of My Sensitive Personal Information</div>
      <p>
        If You are a California resident, You have the right to limit the use and disclosure of Your sensitive personal
        information to that use which is necessary to perform the services or provide the goods reasonably expected by
        an average consumer who requests such services or goods.
      </p>
      <p>
        We collect, use and disclose sensitive personal information in ways that are necessary to provide the Service.
        For more information on how We use Your personal information, please see the "Use of Your Personal Data" section
        or contact us.
      </p>
      <h4>"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)</h4>
      <p>Our Service does not respond to Do Not Track signals.</p>
      <p>
        However, some third party websites do keep track of Your browsing activities. If You are visiting such websites,
        You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can
        enable or disable DNT by visiting the preferences or settings page of Your web browser.
      </p>
      <h4>Your California Privacy Rights (California's Shine the Light law)</h4>
      <p>
        Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an
        established business relationship with us can request information once a year about sharing their Personal Data
        with third parties for the third parties' direct marketing purposes.
      </p>
      <p>
        If You'd like to request more information under the California Shine the Light law, and if You are a California
        resident, You can contact Us using the contact information provided below.
      </p>
      <h4>California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</h4>
      <p>
        California Business and Professions Code Section 22581 allows California residents under the age of 18 who are
        registered users of online sites, services or applications to request and obtain removal of content or
        information they have publicly posted.
      </p>
      <p>
        To request removal of such data, and if You are a California resident, You can contact Us using the contact
        information provided below, and include the email address associated with Your account.
      </p>
      <p>
        Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted
        online and that the law may not permit or require removal in certain circumstances.
      </p>
      <h4>Children's Privacy</h4>
      <p>
        Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
        information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child
        has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data
        from anyone under the age of 13 without verification of parental consent, We take steps to remove that
        information from Our servers.
      </p>
      <p>
        If We need to rely on consent as a legal basis for processing Your information and Your country requires consent
        from a parent, We may require Your parent's consent before We collect and use that information.
      </p>
      <h4>Links to Other Websites</h4>
      <p>
        Our Service may contain links to other websites that are not operated by Us. If You click on a third party link,
        You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every
        site You visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content, privacy policies or practices of any third
        party sites or services.
      </p>
      <h4>Changes to this Privacy Policy</h4>
      <p>
        We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy
        Policy on this page.
      </p>
      <p>
        We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective
        and update the "Last updated" date at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.
      </p>
      <h4>Contact Us</h4>
      <p>If You have any questions about this Privacy Policy, You can contact us:</p>
      <ul>
        <li>By email: <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isPrevRouteRegister() {
      return this.$store.getters.previousRouteState && this.$store.getters.previousRouteState.name === 'register';
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@common/style/info.page';
.readable_content {
  word-wrap: break-word;
  div.offset {
    padding-left: 16px;
  }
  li.offset {
    margin-left: 16px;
  }
  ul {
    margin-block-start: 12px;
    margin-block-end: 12px;
  }
}
</style>
